exports.components = {
  "component---src-layouts-templates-artefact-detail-tsx": () => import("./../../../src/layouts/templates/ArtefactDetail.tsx" /* webpackChunkName: "component---src-layouts-templates-artefact-detail-tsx" */),
  "component---src-layouts-templates-article-detail-tsx": () => import("./../../../src/layouts/templates/ArticleDetail.tsx" /* webpackChunkName: "component---src-layouts-templates-article-detail-tsx" */),
  "component---src-layouts-templates-children-gallery-detail-tsx": () => import("./../../../src/layouts/templates/ChildrenGalleryDetail.tsx" /* webpackChunkName: "component---src-layouts-templates-children-gallery-detail-tsx" */),
  "component---src-layouts-templates-event-detail-tsx": () => import("./../../../src/layouts/templates/EventDetail.tsx" /* webpackChunkName: "component---src-layouts-templates-event-detail-tsx" */),
  "component---src-layouts-templates-gallery-detail-tsx": () => import("./../../../src/layouts/templates/GalleryDetail.tsx" /* webpackChunkName: "component---src-layouts-templates-gallery-detail-tsx" */),
  "component---src-layouts-templates-mega-events-tsx": () => import("./../../../src/layouts/templates/MegaEvents.tsx" /* webpackChunkName: "component---src-layouts-templates-mega-events-tsx" */),
  "component---src-layouts-templates-theme-detail-tsx": () => import("./../../../src/layouts/templates/themeDetail.tsx" /* webpackChunkName: "component---src-layouts-templates-theme-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-amenities-and-accessibility-tsx": () => import("./../../../src/pages/amenities-and-accessibility.tsx" /* webpackChunkName: "component---src-pages-amenities-and-accessibility-tsx" */),
  "component---src-pages-collaboration-page-tsx": () => import("./../../../src/pages/collaborationPage.tsx" /* webpackChunkName: "component---src-pages-collaboration-page-tsx" */),
  "component---src-pages-collection-listing-tsx": () => import("./../../../src/pages/collection-listing.tsx" /* webpackChunkName: "component---src-pages-collection-listing-tsx" */),
  "component---src-pages-collection-tsx": () => import("./../../../src/pages/collection.tsx" /* webpackChunkName: "component---src-pages-collection-tsx" */),
  "component---src-pages-conservation-lab-tsx": () => import("./../../../src/pages/conservation-lab.tsx" /* webpackChunkName: "component---src-pages-conservation-lab-tsx" */),
  "component---src-pages-event-archive-tsx": () => import("./../../../src/pages/event-archive.tsx" /* webpackChunkName: "component---src-pages-event-archive-tsx" */),
  "component---src-pages-event-listing-tsx": () => import("./../../../src/pages/event-listing.tsx" /* webpackChunkName: "component---src-pages-event-listing-tsx" */),
  "component---src-pages-g-20-tsx": () => import("./../../../src/pages/g-20.tsx" /* webpackChunkName: "component---src-pages-g-20-tsx" */),
  "component---src-pages-gt-detail-tsx": () => import("./../../../src/pages/gtDetail.tsx" /* webpackChunkName: "component---src-pages-gt-detail-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-with-us-tsx": () => import("./../../../src/pages/learn-with-us.tsx" /* webpackChunkName: "component---src-pages-learn-with-us-tsx" */),
  "component---src-pages-media-page-tsx": () => import("./../../../src/pages/media-page.tsx" /* webpackChunkName: "component---src-pages-media-page-tsx" */),
  "component---src-pages-membership-page-tsx": () => import("./../../../src/pages/membership-page.tsx" /* webpackChunkName: "component---src-pages-membership-page-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-notice-tsx": () => import("./../../../src/pages/notice.tsx" /* webpackChunkName: "component---src-pages-notice-tsx" */),
  "component---src-pages-resource-tsx": () => import("./../../../src/pages/resource.tsx" /* webpackChunkName: "component---src-pages-resource-tsx" */),
  "component---src-pages-sgt-detail-tsx": () => import("./../../../src/pages/sgt-detail.tsx" /* webpackChunkName: "component---src-pages-sgt-detail-tsx" */),
  "component---src-pages-shop-and-dine-tsx": () => import("./../../../src/pages/shop-and-dine.tsx" /* webpackChunkName: "component---src-pages-shop-and-dine-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-tenders-tsx": () => import("./../../../src/pages/tenders.tsx" /* webpackChunkName: "component---src-pages-tenders-tsx" */),
  "component---src-pages-venue-for-hire-tsx": () => import("./../../../src/pages/venue-for-hire.tsx" /* webpackChunkName: "component---src-pages-venue-for-hire-tsx" */),
  "component---src-pages-visit-tsx": () => import("./../../../src/pages/visit.tsx" /* webpackChunkName: "component---src-pages-visit-tsx" */),
  "component---src-pages-workwithus-tsx": () => import("./../../../src/pages/workwithus.tsx" /* webpackChunkName: "component---src-pages-workwithus-tsx" */)
}

